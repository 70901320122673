.container-login {
  align-items: center;
  display: flex;
  height: calc(100vh - 56px); // 56px is navbar height
  justify-content: center;
  text-align: center;

  .login-form-wrapper {
    margin-bottom: 15%;

    .logo {
      margin-bottom: 10%;
    }
  }
}

.container-not-found {
  align-items: center;
  display: flex;
  height: calc(100vh - 56px); // 56px is navbar height
  justify-content: center;
  padding: 5%;
  text-align: center;

  .logo {
    margin-bottom: 10%;
  }
}

.container-upload-transactions {
  margin: 10px;

  .upload-drag-drop-area {
    height: calc(100vh - 76px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;

    &.dragging {
      border-color: #6D8298;
    }
  }
}

.component-inline-switcher {
  .btn-group {
    width: 100%;

    .btn-outline-primary:active, .btn-outline-primary:hover {
      background: white;
      color: #6D8298;
    }
  }
}

.component-date-picker {
  .DateInput_input__focused {
    border-color: #6D8298;
  }
}

.CalendarDay__selected {
  background: #6D8298 !important;
  border: 1px double #6D8298 !important;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span {
  background: #88a5c3 !important;
  border: 1px double #6D8298 !important;
  color: white !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: #6D8298 !important;
}
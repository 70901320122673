.component-event {
  .card {
    margin: 10px 0;

    .card-header {
      display: flex;
      background: #F6EAE0;
      align-items: center;
      justify-content: space-between;
    }

    .partly-paid-in-cash, .generate-invoice, .invoice-links {
      margin-top: 10px;
    }

    .invoice-links a {
      margin-right: 10px;
    }

    .component-ereceipt-button {
      margin-top: 10px;
      text-align: center;
      display: block;
    }

    .controls {
      display: inherit;

      .skip-button {
        cursor: pointer;
      }

      .spinner-grow {
        margin-right: 2px;
      }
    }

    .last-event-in-location{
      margin-top: 10px;
      font-weight: bold;
    }

    &.cancelled {
      filter: grayscale(100%);

      .card-body {
        background: repeating-linear-gradient(
                        45deg,
                        #ececec,
                        #ececec 10px,
                        #dadada 10px,
                        #dadada 20px
        );
      }
    }

    &.missed-with-due-payment {
      .card-header {
        background-color: #ff82a4;
      }

      .card-body {
        background: repeating-linear-gradient(
                        45deg,
                        rgba(255, 130, 164, 0.5),
                        rgba(255, 130, 164, 0.5) 10px,
                        #F6EAE0 10px,
                        #F6EAE0 20px
        );
      }
    }

    &.skipped {
      filter: grayscale(100%);

      .card-header {
        border-bottom: 0px;
      }
    }

    &.prepaid .card-header {
      background-color: #fff792;
    }

    &.paid .card-header {
      background-color: #9bffc3;
    }

    &.error .card-header {
      background-color: red;
    }
  }

  .forced-reporting-date {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .forced-reporting-date-label {
      margin-right: 10px;
    }
  }
}

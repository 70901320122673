.component-transaction {
  .controls {
    display: inherit;

    .ignore-button {
      cursor: pointer;
    }

    .spinner-grow {
      margin-right: 2px;
    }
  }

  .event-description {
    margin-bottom: 10px;
    font-weight: 500;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .accounting-date {
    align-items: center;
    display: flex;

    .component-date-picker{
      margin-left: 5px;
    }
  }

  .invoice-info {
    color: #ff82a4;
    font-weight: 700;
  }

  .remove-event {
    cursor: pointer;
    color: #ff82a4;
  }

  .card {
    margin: 10px 0;

    .card-header {
      display: flex;
      background: #F6EAE0;
      align-items: center;
      justify-content: space-between;
    }

    &.incorrectly-assigned {
      .card-header {
        background: #ff82a4;
      }
    }

    &.assigned {
      .card-header {
        background: #9bffc3;
      }
    }

    &.skipped {
      filter: grayscale(100%);

      .card-header {
        border-bottom: 0px;
      }
    }
  }
}

.container-transactions {
  margin: 10px;

  @media (min-width: 600px) {
    > .component-dated-item-list > .date-picker-container {
      display: flex;

      .form-check {
        margin-top: 14px;
        margin-left: 10px;
      }
    }
  }
}

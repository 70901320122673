.container-app {
  color: rgba(0, 0, 0, 0.9);
}

.margin-top {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}
$theme-colors: (
  "primary": #6D8298,
  "secondary": #F6EAE0,
);

@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  color: #7a7a7a;
  font-family: "Roboto", Roboto, Sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

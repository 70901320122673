.component-cash-summary {
  ol {
    padding: 0;
    padding-left: 30px;

    ul {
      padding: 0;
      padding-left: 10px;
      li {
        line-height: 2.5em;
      }
    }
  }
  .cash-summary-item {
    .not-paid {
      color: #ff82a4;
      font-weight: 700;
    }
  }
}
